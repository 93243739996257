<template>
  <div>
    <v-app-bar app color="#1a265a" dark elevation="0" height="75">
      <v-app-bar-nav-icon @click.stop="changeDrawe"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          v-if="visibleTitle"
          alt="Pedbem ADMIN"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/gerentor_green.png"
          width="130"
        />
        <!--
        <h1 class="overline text-h6" v-if="visibleTitle">Gerentor</h1>-->
      </div>
      <v-spacer></v-spacer>

      <v-autocomplete
        rounded
        v-model="searchRoute"
        class="search-input"
        :items="menu"
        item-text="title"
        item-value="link"
        flat
        hide-no-data
        hide-details
        label="Menu"
        solo-inverted
        append-icon="mdi-magnify"
        @change="searchTo()"
        height="20"
      >
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn text @click="logout">
        <v-icon size="28px">mdi-arrow-right-bold-box-outline</v-icon>
        <span>Sair</span>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>fas fa-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item width="8rem" text style="text-decoration: none">
            <v-switch
              @change="setDark"
              v-model="user.darkTheme"
              label="Modo Escuro"
            ></v-switch>
          </v-list-item>

          <v-list-item width="8rem" text style="text-decoration: none">
            <v-switch
              @change="setMenu"
              v-model="user.visibleMenu"
              label="Menu Visível"
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
/*eslint-disable */
import Vue from "vue";
import http from "@/services/http";
export default Vue.extend({
  name: "Navbar",
  data: () => ({
    searchRoute: "",
    visibleTitle: true,
    user: {
      visibleMenu: true,
      darkTheme: false,
    },
    menu: [
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/" },

      {
        title: "Vendas",
        icon: "mdi-sale",
        link: "/base/sales/home",
      },
      {
        title: "Extrato Conta",
        icon: "mdi-finance",
        link: "/finance/account-reports",
      },
      {
        title: "Transações",
        icon: "mdi-finance",
        link: "/finance/movements",
      },
      {
        title: "Contas a Receber",
        icon: "mdi-currency-usd",
        link: "/finance/report/account-receivables",
      },

      {
        title: "Contas a Pagar",
        icon: "mdi-currency-usd-off",
        link: "/finance/report/account-payables",
      },
      {
        title: "Estoque",
        icon: "mdi-form-select",
        link: "/base/stock-transactions",
      },
      {
        title: "Clientes",
        icon: "mdi-card-account-details-outline",
        link: "/base/customers",
      },
      {
        title: "Funcionários",
        icon: "mdi-account-group",
        link: "/base/employees",
      },
      {
        title: "Produtos",
        icon: "mdi-food",
        link: "/base/products",
      },
      {
        title: "Fornecedores",
        icon: "mdi-office-building-marker",
        link: "/base/suppliers",
      },
    ],
  }),
  methods: {
    searchTo() {
      this.$router.push(this.searchRoute);
    },
    changeDrawe() {
      this.visibleTitle = !this.visibleTitle;
      this.$root.$emit("changeDrawe");
    },
    logout() {
      localStorage.clear();
      location.reload();
      location.href = "/login";
    },
    setDark(isDark) {
      const payload = {
        isDark,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    setMenu(value) {
      this.user.visibleMenu = value;
      const payload = {
        visibleMenu: value,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    init() {
      const us = localStorage.getItem("user");
      if (us && typeof us === "string") {
        const vs = JSON.parse(us);
        this.visibleTitle = !vs.visibleMenu;
        this.user = vs;
      }
    },
  },
  mounted() {
    this.init();
  },
});
</script>
